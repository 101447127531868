import React, {useEffect} from "react";
import contact from '../Icons/contact.png'
import sendingbar from '../Icons/sendingbar.gif'
import Button from '@mui/material/Button';
import {Box, TextField} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const Contact = ({handlePageLocation, handlePictureChange, changeFavicon}) => {
    handlePageLocation("Contact")
    handlePictureChange(contact)
    changeFavicon(contact)

    useEffect(() => {
        document.title = "Contact Information"
    }, [])
    //================================================================================================================//
    // State variables
    //Changing Email Text
    const [email, setEmail] = React.useState("");
    const handleEmail= (event) => {
        setEmail(event.target.value);
    };

    //Changing Subject Text
    const [subject, setSubject] = React.useState("");
    const handleSubject = (event) => {
        setSubject(event.target.value);
    };

    //Changing Body Text
    const [body, setBody] = React.useState("");
    const handleBody = (event) => {
        setBody(event.target.value);
    };

    const [emailSendState, setEmailSendState] = React.useState("Initial");
    const handleEmailSendState = (event) => {
        setEmailSendState(event.target.value);
    };

    //Required Fields ================================================================================================//
    const [disableTextBox, setDisableTextBox] = React.useState(false);
    const handleDisableTextBox = (event) => {
        setDisableTextBox(event);
    };

    const [requiredEmail, setRequiredEmail] = React.useState(false);
    const handleRequiredEmail = (event) => {
        setRequiredEmail(event);
    };

    const [requiredSubject, setRequiredSubject] = React.useState(false);
    const handleRequiredSubject = (event) => {
        setRequiredSubject(event);
    };

    const [requiredMessage, setRequiredMessage] = React.useState(false);
    const handleRequiredMessage = (event) => {
        setRequiredMessage(event);
    };

    //================================================================================================================//
    //Functions
    //Send Emails to smtp-server
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    async function sendEmail () {
        if ((email !== "") && (subject !== "") && (body !== "")) {
            let valid_check = validateEmail(email)
            console.log(valid_check)
            if (valid_check) {
                handleRequiredEmail(false)
                handleDisableTextBox(true)
                setEmailSendState("Sending")
                axios.post('https://server.bryanwandrych.com/send_email', {
                    "email": email,
                    "subject": subject,
                    "message": body,
                }).then((response) => {
                    console.log(response.data)
                    setEmailSendState("Sent")
                }, (error) => {
                    console.log(error);
                });
            } else {
                alert("Entered Email is Invalid")
                handleRequiredEmail(true)
            }
        } else {
            let alert_string = "Please Fill Out the Following:\n"

            if (email === "") {
                handleRequiredEmail(true)
                alert_string += "• Email\n"
            } else {
                handleRequiredEmail(false)
            }
            if (subject === "") {
                handleRequiredSubject(true)
                alert_string += "• Subject\n"
            } else {
                handleRequiredSubject(false)
            }
            if (body === "") {
                handleRequiredMessage(true)
                alert_string += "• Email Message\n"
            } else {
                handleRequiredMessage(false)
            }

            alert(alert_string)
        }
    }

    function resetInformation() {
        setEmail("")
        setSubject("")
        setBody("")
        setEmailSendState("Initial")
        handleDisableTextBox(false)
    }

    //================================================================================================================//
    // Rendering
    const emailStateRendering = () => {
        if (emailSendState === "Initial") {
            return(
                <div>
                    <br/>
                    <Box textAlign='center'>
                        <Button variant="contained" onClick={sendEmail}>Send Email</Button>
                    </Box>
                </div>
            )
        }

        if (emailSendState === "Sending") {
            return (
                <div>
                    <br/>
                    <img src={sendingbar} width={"100%"} />
                </div>
            )
        }

        if (emailSendState === "Sent") {
            return (
                <center>
                    <div>
                        <hr/>
                        <h3>Your email has successfully been sent to bwandrych@bryanwandrych.com.</h3>
                        <h3>I will reply to you as soon as I can.</h3>
                        <table style={{marginLeft: "auto", marginRight: "auto", border: "1px solid black"}}>
                            <tr>
                                <th><td>From:</td></th>
                                <td>{email}</td>
                            </tr>
                            <tr>
                                <th><td>Subject:</td></th>
                                <td>{subject}</td>
                            </tr>
                            <tr>
                                <th><td>Email Message/Body:</td></th>
                                <td> {body}</td>
                            </tr>
                        </table>
                        <hr/>
                    </div>
                    <div>
                        <br/>
                        <Button variant="contained" onClick={resetInformation}>Send Another Email</Button>
                    </div>
                </center>
            )
        }
    }
    return (
        <div>
            <br/>
            <Box textAlign='center'>
                <h2>Would you like to send me an email?</h2>
            </Box>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 0, width: '100%' },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <TextField
                        id="outlined-password-input"
                        label="Your Email Address"
                        value={email}
                        onChange={handleEmail}
                        disabled={disableTextBox}
                        error={requiredEmail}
                    />
                </div>
                <div>
                    <br/>
                    <TextField
                        id="outlined-password-input"
                        label="Subject"
                        value={subject}
                        onChange={handleSubject}
                        disabled={disableTextBox}
                        error={requiredSubject}
                    />
                </div>
                <div>
                    <br/>
                    <TextField
                        id="outlined-multiline-flexible"
                        label="Email Message/Body"
                        multiline
                        minRows={10}
                        value={body}
                        onChange={handleBody}
                        disabled={disableTextBox}
                        error={requiredMessage}
                    />
                </div>
            </Box>

            {emailStateRendering()}
        </div>
    );
};

export default Contact;